<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ surveyError }}
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card>
        <section-header :is-collapsable="false">Add Survey</section-header>
        <v-card-text>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <v-form ref="form" @submit.prevent="handleSubmit(submit)">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Title"
                      rules="required|max:255"
                    >
                      <v-text-field
                        v-model="title"
                        counter
                        maxlength="255"
                        hint="The title of the Survey"
                        label="Title"
                        prepend-icon="mdi-account"
                        required
                        aria-required="true"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required, max } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import SectionHeader from "@admin/components/SectionHeader.vue";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

export default {
  name: "Form",
  components: {
    SectionHeader,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    engagementId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      snackbar: false,
    };
  },
  computed: {
    ...mapState("surveys", { surveyError: "error", survey: "survey" }),
    ...mapFields("surveys", {
      title: "survey.title",
      surveyEngagementId: "survey.engagementId",
    }),
    cancelButtonRoute() {
      return {
        name: "EngagementView",
        params: { id: this.engagementId },
      };
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("surveys", {
      addSurvey: "addSurvey",
      clearSurvey: "clearSurvey",
    }),
    submit() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;
      this.addSurvey().then(([success]) => {
        this.isSubmitting = false;
        if (success && this.survey.id) {
          this.$router.push({
            name: "SurveysEdit",
            params: { id: this.survey.id },
          });
        }
      });
    },
  },
  mounted() {
    // Ensure we are working on a fresh survey when creating a new one
    this.clearSurvey();
    // Ensure the survey that is to be saved is linked to the engagement
    // that is being targeted
    this.surveyEngagementId = this.engagementId;
    this.setTitleObject("Survey");
    this.setBreadcrumbs();
    let actionButtons = [
      {
        attributes: {
          to: "/engagements/view/" + this.engagementId,
        },
        component: "CancelButton",
        index: 0,
      },
      {
        attributes: {
          onSubmit: () => this.submit(),
        },
        component: "SaveButton",
        index: 1,
      },
    ];
    this.setActionButtons(actionButtons);
  },

  destroyed() {
    this.clearSurvey();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>
